import React from "react";
import styles from "./ActivePlan.module.css";
import { plan1, plan2, plan3 } from "../../../images/image";
import SinglePlan from "./SinglePlan/SinglePlan";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";
import {toast} from "react-toastify";

const ActivePlan = () => {
  let api = process.env.REACT_APP_API_URL;
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  async function getActivePlan() {
   try {
    let { data, status } = await axios.get(api + "/user/get-investments", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });
    
    
    // if (status == 401 ){

    //   toast.error("Session expired, please login again");
    //   setTimeout(() => {
    //     toast.dismiss();
    //   }, 3000);
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("auth-token");
    //   window.location.href = "/login";
    // }
    console.log(data);
    let plans_array = [];

    for (let i = 0; i < data.data.length; i++) {
      let plan = data.data[i];
      let plan_obj = {
        image: plan1,
        id: plan.id,
        investedAmount: plan.amount / Math.pow(10, 8),
        startDate: new Date(plan.start_date).toLocaleString(),
        endDate: new Date(plan.end_date).toLocaleString(),
        totalReturn: plan.roi,
      };
      plans_array.push(plan_obj);
    }
    setPlans(plans_array);
   } catch (e) {
      console.log(e);
      if (e.response.status == 401 ){
        Cookies.remove("auth-token");
        toast.error("Session expired, please login again");
        window.location.href = "/login";
      }
   }
  }

  useEffect(() => {
    getActivePlan();
  }
  , []);

  
  return (
    <section className={styles.wrapper}>
      <h5 className={styles.title}>Active Plan ({plans.length})</h5>
      <div className={styles.allPlan}>
        {plans.map((el, i) => (
          <SinglePlan key={i} {...el} />
        ))}
      </div>
    </section>
  );
};

export default ActivePlan;
