import { useEffect } from "react";
import { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./Component/Header/Header";
import OurPlans from "./pages/OurPlans/OurPlans";
import LeaderBoard from "./pages/LeaderBoardPage/LeaderBoardPage";
import InvestmentHistoryPage from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import { DepositHistoryPage } from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import MyStakes from "./pages/MyStakes/PlanDetailsPage";
import Login from "./pages/LoginSignUp/Login";
import SignUp from "./pages/LoginSignUp/SignUp";
import MyProfile from "./pages/MyProfile/MyProfile";
import MakeYourPayment from "./pages/MakeYourPayment/MakeYourPayment";
import InvestmentPlan from "./pages/InvestmentPlan/InvestmentPlan";
import Portfolio from "./pages/Portfolio/Portfolio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import Footer from "./Component/Footer/Footer";
import Home1 from "./pages/Home1/Home1";
import Contact from "./pages/Home2/Contact";
import Company from "./pages/Home2/Company";
import Career from "./pages/Home2/Career";
import Realstate from "./pages/Home2/Realstate";
import Home2 from "./pages/Home2/Home2";
import Header2 from "./Component/Home2/Header/Header";
import Footer2 from "./Component/Home2/Footer/Footer";
import Terms from "./pages/Home2/Terms";
import Privacy from "./pages/Home2/Privacy";
import ForgotPassword from "./pages/LoginSignUp/ForgotPassword";

function App() {
  let api = process.env.REACT_APP_API_URL;
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    document.title = process.env.REACT_APP_APP_NAME || "Dashboard";
  }, []);

  let getUserInfo = async () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/company"||
      window.location.pathname === "/career"||
      window.location.pathname === "/contact"||
      window.location.pathname === "/forgotCode" || 
      window.location.pathname === "/forgotCode"

    )
      return;

    let token = Cookies.get("auth-token");
    console.log(token, "token");
    if (!token) {
      if (window.location.pathname != "/login") {
        toast.error("Session expired, please login again");
        window.location.href = "/login";
      }
      return;
    }

    try {
      let { data, status } = await axios.get(api + "/user/data", {
        headers: {
          "Content-Type": "application/json",
          "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
          Authorization: "Bearer " + user_id,
        },
      });
      let balance = data.data?.balance / Math.pow(10, 8);
      let uns_balance = data.data?.uns_balance / Math.pow(10, 8);
      setUserInfo({ ...data.data, balance, uns_balance });
      localStorage.setItem("evm_wallet", data.data["evm_wallet"]);
      console.log(data.data);
    } catch (e) {
      console.log(e);
      if (e.response.status == 401) {
        Cookies.remove("auth-token");
        toast.error("Session expired, please login again");
        window.location.href = "/login";
      }
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [user_id]);

  const Register = async (e, values, ref) => {
    e.preventDefault();
    let url = process.env.REACT_APP_API_URL;
    let body = {
      email: values.email,
      password: values.password,
      first_name: values.firstname,
      last_name: values.lastname,
      user_name: values.username,
      password: values.password,
      security_pin: values.passcode,
      affiliate_wallet: values.affiliate_wallet,
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    };

    let { data, status } = await axios.post(url + "/auth/register", body, {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + localStorage.getItem("token") || "f",
      },
    });

     if (status == 200) {
      if (data.message === "User created") {
        console.log("user created");
        alert("User created successfully");
        window.location.href = "/login";
        // toast.success("User created successfully");
        toast.loading("Redirecting to login");
        setTimeout(() => {
          toast.dismiss();
          window.location.href = "/login";
          navigate("/login");
        }, 3000);
      } else {
        toast.error(data.message, {
          autoClose: 3000,
          position: "top-center",
        });
      }
    } else {
      console.log(data);
      toast.error(data.message);
    }

    window.location.href = "/login";
    window.open("/login", "_self");
   
  };

  const navigate = useNavigate();
  const location = useLocation();
  const hideComponent =
    location.pathname === "/" ||
    location.pathname === "/signup" ||
    location.pathname.startsWith("/services") ||
    location.pathname.startsWith("/career") ||
    location.pathname.startsWith("/company") ||
    location.pathname.startsWith("/contact") ||
    location.pathname.startsWith("/privacy") ||
    location.pathname.startsWith("/terms");
  const hideHeader =
    location.pathname === "/" ||
    location.pathname.startsWith("/services") ||
    location.pathname.startsWith("/career") ||
    location.pathname.startsWith("/company") ||
    location.pathname.startsWith("/contact") ||
    location.pathname.startsWith("/privacy") ||
    location.pathname.startsWith("/terms");
  // useEffect(() => {
  //   console.log(user_id, "user_id in useeffect");
  //   if (user_id === "undefined" || !user_id) {
  //     navigate("/login");
  //   }
  // }, []);
  return (
    <>
      {!hideHeader && <Header userInfo={userInfo} />}
      <ToastContainer />

      {!hideComponent && (
        <div className="container home1">
          <ToastContainer />{" "}
          <div className="mainContainer">
            <div className="mainWrapper">
              {" "}
              <Routes>
                <Route
                  path="/dashboard"
                  element={<OurPlans userInfo={userInfo} />}
                />{" "}
                <Route path="/leaderboard" element={<LeaderBoard />} />
                <Route path="/investments/:id" element={<MyStakes />}></Route>
                <Route
                  path="/history"
                  element={<InvestmentHistoryPage />}
                ></Route>
                <Route
                  path="/deposit-history"
                  element={<DepositHistoryPage />}
                ></Route>
                <Route
                  path="/profile"
                  element={<MyProfile userInfo={userInfo} />}
                ></Route>
                <Route path="/deposit" element={<MakeYourPayment />}></Route>
                <Route
                  path="/plans"
                  element={
                    <InvestmentPlan
                      userInfo={userInfo}
                      getUserInfo={getUserInfo}
                    />
                  }
                ></Route>
                <Route
                  path="/portfolio"
                  element={<Portfolio userInfo={userInfo} />}
                ></Route>
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotCode" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignUp Register={Register} />} />
        <Route path="/signup/:ref" element={<SignUp Register={Register} />} />
        {/*  <Route path="/" element={<Home1 />} /> */}
      </Routes>

      {hideComponent && (
        <>
          <Routes>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/company" element={<Company />} />
            <Route path="/career" element={<Career />} />
            <Route path="/services/realstate" element={<Realstate />} />
            <Route path="/" element={<Home2 />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
