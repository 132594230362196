import React from "react";
import styles from "./Actions.module.css";
import SingleAction from "./SingleAction";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";


const Actions = ({ investData = { investData } }) => {

  let withdrawProfitHandler = async () => {
    let api = process.env.REACT_APP_API_URL;
    let user_id = Cookies.get("auth-token");
    let url = api + "/user/withdraw-profits/" + investData.id;
    let { data, status } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + user_id,
      },
    });

    if (status === 200) {
      toast.success(data.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  let withdrawCapitalHandler = async () => {
    toast.error("This feature is not available yet")
  }



  const actionData = [

    {
      title: "My Invested Amount",
      value: investData.amount / Math.pow(10, 8),
      inUsd: investData.amount / Math.pow(10, 8),
      buttonText: "Withdraw".toUpperCase(),
      handler : withdrawCapitalHandler
    },
    {
      title: "Accumulated Profits",
      value: investData.investment_balance / Math.pow(10, 8),
      inUsd: investData.investment_balance / Math.pow(10, 8),
      buttonText: "Claim rewards".toUpperCase(),
      handler : withdrawProfitHandler
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Actions</h3>
      <div className={styles.boxWrapper}>
        {actionData.map((el, i) => (
          <SingleAction {...el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Actions;
